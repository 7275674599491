import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import {
  Dashboard,
  Register,
  Login,
  Deposit,
  Withdraw,
  Trading,
  Manage,
  Buy,
  Assets,
  Verify,
  Forgot,
  Subscribe,
  Profile,
  NFTs,
  Staking,
  CopyExperts,
} from "./pages/index";
import { Toaster } from "react-hot-toast";
import NewRegister from "./pages/NewRegister";
import NewVerify from "./pages/NewVerify";
import Markets from "./pages/Markets";
import Signals from "./pages/Signals";
import SignalDeposit from "./pages/SignalDeposit";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Login />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/buy" element={<Buy />} />
          <Route path="/subscribe" element={<Subscribe />} />
          <Route path="/register" element={<Register />} />
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/assets" element={<Assets />} />
          <Route path="/withdraw" element={<Withdraw />} />
          <Route path="/withdrawal" element={<Withdraw />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/trade" element={<Trading />} />
          <Route path="/signals" element={<Signals />} />
          <Route path="/signal-deposit" element={<SignalDeposit />} />
          <Route path="/manage" element={<Manage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/copy" element={<CopyExperts />} />
          <Route path="/stake" element={<Staking />} />
          <Route path="/nfts" element={<NFTs />} />
          <Route path="/newregister" element={<NewRegister />} />
          <Route path="/newverify" element={<NewVerify />} />
          <Route path="/markets" element={<Markets />} />
          <Route path="/trade/:type/:asset" element={<Trading />} />
          {/* <Route path="/extra/facebook" element={<Facebook />} /> */}
          {/* <Route path="/extra/twitter" element={<Twitter />} /> */}
        </Routes>
      </Router>
      <Toaster />
    </div>
  );
}

export default App;
